import React from 'react';
import styled from '@emotion/styled';

import Text from '../common/Text';
import Heading3 from '../common/Heading3';
import Column from '../common/layout/Column';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  size: string;
  price: number;
  currency: string;
  nonReturnableReason?: string;
  quantity: number;
  isQuantityHidden?: boolean;
}

const Container = styled('dl')`
  margin-left: 16px;
  overflow: hidden;
`;

const Title = styled('dt')`
  width: 100%;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

const NotEligibleReason = styled(Text)`
  display: block;
  font-weight: 500;
  margin-top: 8px;
`;

const Detail = styled('dd')`
  margin: 0;
`;

function ProductInfo(props: Props) {
  const { name, size, price, currency, quantity, nonReturnableReason, isQuantityHidden } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <Title className={size}>
        <Heading3 lang="zxx">{name}</Heading3>
      </Title>
      <Column spacing={4}>
        {size && (
          <Detail>
            <Text secondary small>
              {t('productSize')}: {size}
            </Text>
          </Detail>
        )}
        <Detail>
          <Text secondary small>
            {t('productPrice')}: {formatPrice(price, currency)}
          </Text>
        </Detail>

        {!isQuantityHidden && (
          <Detail>
            <Text secondary small>
              {t('productQuantity')}: {quantity}
            </Text>
          </Detail>
        )}
      </Column>
      {nonReturnableReason && <NotEligibleReason small>{t(nonReturnableReason)}</NotEligibleReason>}
    </Container>
  );
}

function formatPrice(value: number, currency: string): string {
  return `${value.toFixed(2)} ${currency}`;
}

export default React.memo(ProductInfo);
