import React, { createRef, Ref, RefObject, useEffect, useImperativeHandle, useState } from 'react';
import styled from '@emotion/styled';

import SelectableProductItem from './SelectableProductItem';
import { OverviewReturnItem } from '../../types';
import Column from '../common/layout/Column';
import { scrollToElement } from '../domHelpers';
import ListColumn from '../common/layout/ListColumn';

const Container = styled(Column)`
  margin-left: -12px; // compensates checkboxes extended click area for parent padding to make it visually aligned
`;

interface Props {
  items: OverviewReturnItem[];
  onItemCheck: (productKey: string, checked: boolean) => void;
  onItemReasonChange: (productKey: string, reason: string) => void;
  onQuantityChange: (productKey: string, quantity: number) => void;
}

export type SelectableProductsListRefValue = {
  scrollToItem: (productKey: string) => void;
};

const SelectableProductsList = React.forwardRef((props: Props, ref: Ref<SelectableProductsListRefValue>) => {
  const { items, onItemCheck, onItemReasonChange, onQuantityChange } = props;
  const [refs, setRefs] = useState<{ [id: string]: RefObject<HTMLLIElement> }>({});

  useEffect(() => {
    const refMap = items.reduce((acc: { [productKey: string]: RefObject<HTMLLIElement> }, item) => {
      acc[item.productKey] = createRef();
      return acc;
    }, {});

    setRefs(refMap);
  }, [items]);

  useImperativeHandle(ref, () => ({
    scrollToItem: productKey => scrollToElement(refs[productKey].current),
  }));

  return (
    <Container spacing={0}>
      <ListColumn spacing={24}>
        {items.map(item => (
          <SelectableProductItem
            ref={refs[item.productKey]}
            key={item.productKey}
            item={item}
            onCheck={checked => onItemCheck(item.productKey, checked)}
            onReasonChange={reason => onItemReasonChange(item.productKey, reason)}
            onQuantityChange={quantity => onQuantityChange(item.productKey, parseInt(quantity))}
          />
        ))}
      </ListColumn>
    </Container>
  );
});

export default React.memo(SelectableProductsList);
