import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as FallbackImageIcon } from '../../icons/fallback-image.svg';
import colors from '../common/colors';

interface Props {
  url?: string;
  name?: string;
}

const Wrapper = styled('div')`
  flex-shrink: 0;
`;

const Image = styled('img')`
  display: block;
  width: 80px;
  height: auto;
`;

const FallbackImage = styled(FallbackImageIcon)`
  width: 80px;
  height: 112px;
  fill: ${colors.lightGrey};
  background-color: ${colors.snow};
`;

function ProductImage(props: Props) {
  return (
    <Wrapper>
      {props.url ? (
        <Image src={props.url} alt={props.name || 'product-image'} />
      ) : (
        <FallbackImage data-testid="fallback-image" />
      )}
    </Wrapper>
  );
}

export default React.memo(ProductImage);
