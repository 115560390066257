import React from 'react';
import styled from '@emotion/styled';

interface Props {
  spacing: number;
}

const Column = styled('ul')<Props>`
  display: grid;
  grid-gap: ${(props: Props) => props.spacing}px;
  padding: 0;
  margin: 0;
`;

export default React.memo(Column);
