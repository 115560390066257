import React from 'react';

import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';
import { GroupedReturnItem, ProductListItem, ReturnItem } from '../../types';
import ProductItemContainer from './ProductItemContainer';
import ProductItemOverlay from './ProductItemNotEligibleOverlay';

export type ProductItemProps = ProductListItem;

function NonReturnableProductItem(props: ProductItemProps) {
  const { productImageUrl, name, size, price, currency, totalQuantity } = props;

  return (
    <ProductItemContainer data-testid="product-item">
      <ProductImage url={productImageUrl} name={name} />
      <ProductInfo
        name={name}
        size={size}
        price={price}
        currency={currency}
        quantity={totalQuantity}
        nonReturnableReason={getNonReturnableReason(props)}
      />
      <ProductItemOverlay data-testid="not-eligible-overlay" />
    </ProductItemContainer>
  );
}

function isGroupedReturnItem(item: ProductListItem): item is GroupedReturnItem<ReturnItem> {
  return 'isReturnable' in item;
}

function getNonReturnableReason(item: ProductListItem): string | undefined {
  if (item.returnRequestId) {
    return 'productAlreadyReturned';
  }

  if (isGroupedReturnItem(item) && !item.isReturnable) {
    return 'genericNonEligibleReason';
  }
}

export default React.memo(NonReturnableProductItem);
