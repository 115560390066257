import React from 'react';
import styled from '@emotion/styled';

import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import ProductsListHeading from '../products-overview/ProductsListHeading';
import { ReturnRequestItem } from '../../types';
import { useGroupedItems } from '../../hooks/useGroupedItems';
import ProductItem from '../products-overview/ProductItem';
import ListColumn from '../common/layout/ListColumn';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  &.l,
  &.xl {
    margin-bottom: 32px;
  }
`;

type Props = {
  heading: string;
  items?: ReturnRequestItem[];
};

function ConfirmationProductsList(props: Props) {
  const size = useReturnPortalSize();
  const { heading } = props;
  const items = useGroupedItems(props.items || []);

  return (
    <Container className={size}>
      <ProductsListHeading>{heading}</ProductsListHeading>
      <ListColumn spacing={24}>
        {items.map(item => (
          <ProductItem key={item.productKey} {...item} />
        ))}
      </ListColumn>
    </Container>
  );
}

export default React.memo(ConfirmationProductsList);
