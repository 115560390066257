import React from 'react';
import styled from '@emotion/styled';

import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import LoginForm from './LoginForm';
import Banner from '../page-layout/Banner';
import { useSettings } from '../../hooks/useSettings';
import PageContainer from '../page-layout/PageContainer';
import colors from '../common/colors';
import PageFooter from '../page-layout/PageFooter';
import { isDesktop } from '../../sizeHelpers';
import PagePadding from '../page-layout/PagePadding';
import PageHeader from '../page-layout/PageHeader';

const Content = styled('main')`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${colors.white};

  &.l,
  &.xl {
    width: 488px;
    flex-shrink: 0;
  }
`;

const LoginFormContainer = styled('div')`
  padding-top: 32px;

  &.s,
  &.m {
    max-width: 520px;
    margin: 0 auto;
  }

  &.l,
  &.xl {
    padding-top: 48px;
  }
`;

const DesktopBannerContainer = styled('div')`
  flex-grow: 1;
`;

type Props = {
  hasError?: boolean;
  isLoading?: boolean;
  onSubmit?: (orderId: string, email: string) => void;
  selectedLanguage?: string;
  onLanguageChange?: (language: string) => void;
};

function LoginView(props: Props) {
  const { bannerImageUrl, bannerDesktopImageUrl } = useSettings();
  const size = useReturnPortalSize();
  const isDesktopSize = isDesktop(size);
  const { onLanguageChange, selectedLanguage } = props;

  return (
    <PageContainer layout="row">
      <Content className={size}>
        <PageHeader changeLanguage={onLanguageChange} selectedLanguage={selectedLanguage} />
        {!isDesktopSize && <Banner data-testid="banner" imageUrl={bannerImageUrl} />}
        <PagePadding>
          <LoginFormContainer className={size}>
            <LoginForm {...props} />
          </LoginFormContainer>
        </PagePadding>
        <PageFooter />
      </Content>
      {isDesktopSize && (
        <DesktopBannerContainer>
          <Banner fullHeight data-testid="banner" imageUrl={bannerDesktopImageUrl} />
        </DesktopBannerContainer>
      )}
    </PageContainer>
  );
}

export default React.memo(LoginView);
