import React, { useContext } from 'react';
import styled from '@emotion/styled';

import PageLogo from './PageLogo';
import LanguageSelector, { Option } from '../language-selector/LanguageSelector';
import colors from '../common/colors';
import { useSettings } from '../../hooks/useSettings';
import { ReturnPortalContext } from '../../contexts/ReturnPortalContext';

const HeaderContainer = styled('header')`
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
`;
export interface Props {
  selectedLanguage?: string;
  changeLanguage?: (language: string) => void;
}

function PageHeader(props: Props) {
  const { selectedLanguage, changeLanguage = () => {} } = props;
  const { availableLanguages = [] } = useContext(ReturnPortalContext);
  const { accentColor, isTranslationsEnabled } = useSettings();

  return (
    <HeaderContainer>
      <PageLogo />
      {isTranslationsEnabled && availableLanguages.length > 0 && (
        <LanguageSelector
          color={accentColor}
          value={selectedLanguage}
          options={getSelectOptions(availableLanguages)}
          onChange={changeLanguage}
        />
      )}
    </HeaderContainer>
  );
}

function getSelectOptions(options: string[]): Option[] {
  return options.map(value => ({ value, label: value.toUpperCase() }));
}

export default React.memo(PageHeader);
