import React, { ChangeEvent, useCallback } from 'react';
import styled from '@emotion/styled';

import Text from '../common/Text';
import colors from './colors';

import { ReactComponent as ArrowIcon } from '../../icons/dropdown.svg';
import { ReactComponent as Error } from '../../icons/error.svg';

const Container = styled('div')`
  width: 100%;
  position: relative;
`;

type StyleProps = {
  focusColor: string;
  font: string;
  error: boolean;
};

const StyledSelect = styled('select')<StyleProps>`
  width: 100%;
  height: 40px;
  padding: 0 36px 0 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${props => (props.error ? colors.red : colors.lightGrey)};
  background-color: ${colors.white};
  cursor: pointer;
  appearance: none;
  outline: none;
  font-weight: normal;
  letter-spacing: normal;
  color: #202124;
  font-size: 16px;
  font-family: inherit;

  &:focus {
    border-width: 2px;
    border-color: ${props => (props.error ? colors.red : props.focusColor)};
    padding-left: 15px;
  }
`;

const ArrowContainer = styled('div')`
  position: absolute;
  top: 0;
  right: 8px;
  pointer-events: none;
  height: 40px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${colors.grey};
`;

const ErrorIcon = styled(Error)`
  flex-shrink: 0;
  fill: ${colors.red};
  margin-right: 6px;
`;

const ErrorContainer = styled('div')`
  display: flex;
  box-sizing: border-box;
  margin-top: 6px;
`;

const StyledText = styled(Text)`
  color: ${colors.red};
`;

export type Value = string | number;

export interface Option {
  value: Value;
  label: string;
}

export interface Props {
  name?: string;
  value?: string | number;
  options: Option[];
  onChange?: (value: string, name?: string) => void;
  error?: string | null;
  label?: string;
  color: string;
  font: string;
}

function Select(props: Props) {
  const { value, name, options, onChange = () => {}, error, font, label } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange(event.target.value, name);
    },
    [onChange, name]
  );

  return (
    <Container>
      <label>
        <StyledSelect
          value={value}
          name={name}
          aria-label={label}
          onChange={onChangeHandler}
          focusColor={colors.blue}
          font={font}
          error={!!error}
          aria-invalid={!!error}
          aria-describedby={error ? `${name}-error` : undefined}
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </StyledSelect>
      </label>
      <ArrowContainer aria-hidden="true">
        <ArrowIcon />
      </ArrowContainer>
      {error && (
        <ErrorContainer role="alert" id={`${name}-error`}>
          <ErrorIcon />
          <StyledText>{error}</StyledText>
        </ErrorContainer>
      )}
    </Container>
  );
}

export default React.memo(Select);
