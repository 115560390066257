import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';

import useReturnPortalHash from './useReturnPortalHash';
import { getReturnPortalByHash } from '../api';
import { transformReasonOptionsToTranslations } from '../reasonsHelpers';

type Result = {
  selectedLanguage?: string;
  changeLanguage: (language: string) => void;
};

function useChangeLanguage(): Result {
  const hash = useReturnPortalHash();

  const [state, getReturnPortalForLanguage] = useAsyncFn(language => getReturnPortalByHash(hash, language), []);
  const { value: returnPortal } = state;

  // changeLanguage() is async, so we call useTranslation hook to trigger re-render when language is set
  const { i18n } = useTranslation();

  useEffect(() => {
    if (returnPortal) {
      const { language, translations, settings } = returnPortal;
      const reasonTranslations = transformReasonOptionsToTranslations(settings.reasonOptions);

      i18n.addResources(language, 'common', { ...translations, ...reasonTranslations });
      i18n.changeLanguage(language);

      document.documentElement.lang = language;
    }
  }, [returnPortal, i18n]);

  return {
    selectedLanguage: i18n.language,
    changeLanguage: getReturnPortalForLanguage,
  };
}

export default useChangeLanguage;
