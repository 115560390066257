import React from 'react';
import styled from '@emotion/styled';

import ProductsListHeading from './ProductsListHeading';
import { OverviewReturnItem } from '../../types';
import NonReturnableProductItem from './NonReturnableProductItem';
import ListColumn from '../common/layout/ListColumn';

type Props = {
  items: OverviewReturnItem[];
};

const Container = styled('div')`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

function NonReturnableProducts(props: Props) {
  const { items } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <Container data-testid="non-returnable-items">
      <ProductsListHeading>nonReturnableProductsListHeading</ProductsListHeading>
      <ListColumn spacing={24}>
        {items.map(item => (
          <NonReturnableProductItem key={item.productKey} {...item} />
        ))}
      </ListColumn>
    </Container>
  );
}

export default React.memo(NonReturnableProducts);
