import React from 'react';
import styled from '@emotion/styled';

import Text from '../common/Text';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate } from '../utils';
import { Order } from '../../types';
import { TFunction } from 'i18next';

const Container = styled('div')`
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 20px;
`;

const MobileContainer = styled(Container)`
  display: grid;
  grid-gap: 4px;
`;

const DesktopContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

type Props = {
  order?: Order;
  orientation: 'row' | 'column';
};

const layout = {
  row: DesktopContainer,
  column: MobileContainer,
};

function OrderInfo(props: Props) {
  const { order, orientation } = props;
  const InfoContainer = layout[orientation];
  const { t, i18n } = useTranslation();

  if (!order) {
    return null;
  }

  return (
    <InfoContainer data-test-id="orderInfo">
      <Text>
        <Trans>orderIdLabel</Trans> #{order.shopOrderId}
      </Text>
      <Text small secondary>
        <time dateTime={getDateInfo(order, t, i18n.language)}>
          <Trans>{getDateInfo(order, t, i18n.language)}</Trans>
        </time>
      </Text>
    </InfoContainer>
  );
}

function getDateInfo(order: Order, t: TFunction, language: string) {
  if (order.deliveryDate) {
    return t('orderDeliveredOn', { date: formatDate(order.deliveryDate, language) });
  }

  return t('orderCreatedOn', { date: formatDate(order.creationDate, language) });
}

export default React.memo(OrderInfo);
